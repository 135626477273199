// Here you can add other styles
.logo,
.logo_icon {
    padding: 5px 0;
    max-height: 56px;
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

.logo_icon {
    padding: 10px 5px;
}

.card {
    border-radius: 6px;
    box-shadow: 1px 1px 5px 1px #d2d2d2;
}

.card-body {
    border-radius: 0px;
}

.card-header {
    font-weight: bold;
    color: #7a98ac;
}

.card-header:first-child {
    border-radius: 6px 6px 0 0;
}

.card-footer:first-child {
    border-radius: 0 0 6px 6px;
}

.c-main {
    padding-top: 15px;
    padding-right: 0;
}

.c-main .container-fluid {
    padding: 0 15px 0 15px;
}

.c-header-toggler {
    margin-left: 0 !important;
}

.c-subheader-nav {
    padding-left: 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

img {
    max-height: 80px;
    object-fit: contain;
    width: 100%;
    padding: 5px;
}

.form-group img {
    max-height: 80px !important;
    object-fit: contain !important;
    max-width: 100px !important;
    padding: 5px;
}

img.__react_modal_image__medium_img {
    max-height: 100% !important;
    max-width: 100% !important;
    padding: 15px;
}

.svg-inline--fa {
    cursor: pointer;
}

.removeAvatar {
    position: absolute;
    left: 0;
    top: 0;
}

.removeImage {
    position: relative;
    left: 15px;
    right: 0;
    top: 0;
}

table tr td,
table tbody tr td,
table thead tr th,
table tr th {
    vertical-align: middle !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.c-avatar {
    height: 40px !important;
    width: 80px;
}

.c-avatar img {
    padding: 20px 5px;
}

.spinner {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: #0000000d;
    z-index: 9999;
}

.sk-spinner {
    display: inline-flex;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, .3);
    border-radius: 50%;
    border-top-color: #7a98ac;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.form-group>label {
    font-weight: 600;
    color: #2c2c2c;
}

@media(max-width: 768px) {
    .c-header-toggler {
        margin-left: 5px !important;
    }

    .c-avatar-img {
        max-height: 45px;
    }

    li.c-header-nav-items.mx-2.dropdown.nav-item {
        margin-left: 0 !important;
    }

    .c-header-nav .c-header-nav-link,
    .c-header-nav .c-header-nav-btn {
        padding-left: 0;
    }

    .p-4.card {
        padding: 0.5rem !important;
    }

    .card-header {
        padding: 0.5rem 0.25rem;
    }

    .card-body {
        padding: 0.25rem;
    }

    .card-footer {
        padding: 0.5rem 0.25rem;
    }

    .c-footer {
        padding: 0;
    }

    html:not([dir="rtl"]) .mfs-auto {
        margin: 0 auto;
    }
}

/* width */
::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 50rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
}

abbr:where([title]) {
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.card {
    box-shadow: none;
}