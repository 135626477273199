// Variable overrides
//

$white:  #fff !default;
$black:  #000 !default;

$gray-base:  #000000 !default;
$gray-100:   #F5F4F4 !default;
$gray-200:   #ECEAE9 !default;
$gray-300:   #C6C3C3 !default;
$gray-400:   #8E8B8B !default;
$gray-500:   #434141 !default;
$gray-600:   #392F30 !default;
$gray-700:   #302023 !default;
$gray-800:   #261419 !default;
$gray-900:   #200C13 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100":  $gray-100,
    "200":  $gray-200,
    "300":  $gray-300,
    "400":  $gray-400,
    "500":  $gray-500,
    "600":  $gray-600,
    "700":  $gray-700,
    "800":  $gray-800,
    "900":  $gray-900
  ),
  $grays
);

$primary-base:  #7a98ac !default;
$primary-100:   #6e899b !default;
$primary-200:   #627a8a !default;
$primary-300:   #556a78 !default;
$primary-400:   #495b67 !default;
$primary-500:   #3d4c56 !default;
$primary-600:   #313d45 !default;
$primary-700:   #252e34 !default;
$primary-800:   #181e22 !default;
$primary-900:   #0c0f11 !default;

$primary-dark:  #556978 !default;
$primary:       #7a98ac !default;
$primary-50:    #bdccd6 !default;
$primary-25:    #a2b7c5 !default;

$secondary-base:  #6e777e !default;

$secondary-100:   #7d858b !default;
$secondary-200:   #8b9298 !default;
$secondary-300:   #9aa0a5 !default;
$secondary-400:   #a8adb2 !default;
$secondary-500:   #b7bbbf !default;
$secondary-600:   #c5c9cb !default;
$secondary-700:   #d4d6d8 !default;
$secondary-800:   #e2e4e5 !default;
$secondary-900:   #f1f1f2 !default;

$secondary-dark:  #42525f !default;
$secondary:       #556978 !default;
$secondary-50:    #859aa9 !default;
$secondary-25:    #fce9da !default;


$danger-dark:  #cb2929 !default;
$danger:       #e12d2d !default;
$danger-50:    #ea6c6c !default;
$danger-25:    #f6c0c0 !default;

$info-dark:  #2599cb !default;
$info:       #29abe2 !default;
$info-50:    #7fccee !default;
$info-25:    #d4eef9 !default;

$success-dark:  #63c899 !default;
$success:       #52c28e !default;
$success-50:    #97dabb !default;
$success-25:    #cbeddd !default;

$warning-dark:  #e6ae06 !default;
$warning:       #ffc107 !default;
$warning-50:    #ffda6a !default;
$warning-25:    #fff3cd !default;

$light:  $gray-100 !default;
$dark:   $gray-800 !default;


// scss-docs-start theme-colors-map
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary-25,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval:  8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:   $gray-900 !default;
$yiq-text-light:  $white !default;

$yiq-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$yiq-theme-map: map-merge(
  (
    default: (
      "yiq-text-dark":  $yiq-text-dark,
      "yiq-text-light":  $yiq-text-light
    )
  ),
  $yiq-theme-map
);

// TODO: Move to better place
// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
  ("(","%28"),
  (")","%29"),
) !default;