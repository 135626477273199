// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

// .form-control, input[type=text], select, textarea, .custom-file-label, .rw-widget-input {
//     font-size: 16px !important;
//     background-color: #ECF0F3 !important;
//     box-shadow: inset 2px 2px 2px #EE9248, inset -2px 0px 10px #ffffff !important;
//     border: none !important;
//     border-radius: 0.25rem !important;
//     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
// }
// .form-control:focus {
//     background-color: #ECF0F3 !important;
//     outline: 0 !important;
//     box-shadow: inset 2px 2px 2px #EE9248, inset -2px 0px 10px #ffffff;
// }
.btn-success,
.btn-success:hover {
    color: #fff;
}

.card-footer .btn-group {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.card-footer .btn-group .btn {
    display: inline-block;
    flex: none;
}

::placeholder {
    color: #7c7a7a !important;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    background: $primary-base;
}

.text-theme {
    color: $primary-base;
}

.bold {
    font-weight: bold;
}
